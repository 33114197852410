/* eslint-disable @typescript-eslint/no-explicit-any */
import { FEATURE_SUPPLIER_STRIPE_INVOICES } from "@arowana/flags";
import { Order, OrderLineItem } from "@arowana/graphql";
import { OnWheelBlur } from "@arowana/ui";
import { unitLabel } from "@arowana/util";
import {
  Avatar,
  Badge,
  Box,
  Button,
  ButtonGroup,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  Link,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Routes from "apps/supplier/src/Constants/Routes";
import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import { FlagsmithContext } from "../../../context/FlagsmithContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actions: {
      "& > button + button": {
        marginLeft: theme.spacing(1.5),
      },
    },
    attribute: {
      margin: theme.spacing(0.5, 0),
    },
    attributes: {
      listStyleType: "none",
      margin: 0,
      marginTop: theme.spacing(1),
      padding: 0,
    },
    listItemText: {
      marginLeft: theme.spacing(2),
    },
    sku: {
      borderColor: theme.palette.divider,
      borderRadius: 4,
      borderStyle: "solid",
      borderWidth: 1,
      color: theme.palette.text.secondary,
      marginLeft: theme.spacing(1),
      padding: theme.spacing(0.25, 0.5),
    },
  }),
);

interface LineItemProps {
  currencyFormatter: Intl.NumberFormat;
  index: number;
  onAdjustItem: (index: number, val: any, key: string) => void;
  onRefundLineItem: (amount: string, description: string) => void;
  onRefundLineItemByWeight: (amount: string, unit: string, total: string, description: string) => void;
  order: Order;
  item: OrderLineItem;
}

const LineItem = ({
  currencyFormatter,
  index,
  item: {
    cases: originalCaseCount,
    caseSize,
    pricePerUnit: originalPricePerUnit,
    productId,
    productImage,
    productName,
    quantity: originalQuantity,
    sku,
    subName,
    unit,
    variantId,
  },
  onAdjustItem,
  onRefundLineItem,
  onRefundLineItemByWeight,
  order,
}: LineItemProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.only("xs"));
  const isPaid = order?.status === "paid";

  // We want "adjust quantity" to be "adjust case count".
  const flagsmith = useContext(FlagsmithContext);
  const hasInvoices = flagsmith.hasFeature(FEATURE_SUPPLIER_STRIPE_INVOICES);

  // Quantity
  const [quantity, setQuantity] = useState(0);
  const [displayQuantity, setDisplayQuantity] = useState("");
  useEffect(() => {
    const parsed = parseFloat(originalQuantity.toFixed(2));
    setQuantity(parsed);
    setDisplayQuantity(`${parsed}`);
  }, [originalQuantity]);

  const [openQuantity, setOpenQuantity] = useState(false);
  const handleOpenQuantity = () => setOpenQuantity(true);
  const handleCloseQuantity = () => setOpenQuantity(false);
  const handleChangeQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const parsed = parseFloat(parseFloat(event.target.value).toFixed(4));

    setDisplayQuantity(value);

    if (!isNaN(parsed)) {
      setQuantity(parsed);
    }
  };
  const handleCancelQuantity = () => {
    const parsed = parseFloat(originalQuantity.toFixed(4));
    setQuantity(parsed);
    setDisplayQuantity(`${parsed}`);
    setOpenQuantity(false);
  };
  const handleAdjustQuantity = () => {
    // adjust cases accordingly
    onAdjustItem(index, Math.round(quantity / caseSize), "cases");
    onAdjustItem(index, quantity, "quantity");
    setOpenQuantity(false);
  };
  const handleRemoveItem = () => {
    setQuantity(0);
    onAdjustItem(index, 0, "quantity");
    setOpenQuantity(false);
  };

  // Cases
  const [cases, setCaseCount] = useState(0);
  useEffect(() => {
    setCaseCount(originalCaseCount);

    // line_item_total = case_count * case_size * price_per_unit
    // line_item_total = quantity * price_per_unit
    // Therefore, quantity = case_count * case_size

    // Only execute this if "adjust case size" is visible (therefore
    // "adjust quantity" is visible), because otherwise it interferes
    // with quantity being set because of weird React state stuff.
    if (hasInvoices) {
      setQuantity(originalCaseCount * caseSize);
    }
  }, [originalCaseCount]);

  const [openCaseCount, setOpenCaseCount] = useState(false);
  const handleOpenCaseCount = () => setOpenCaseCount(true);
  const handleCloseCaseCount = () => {
    setOpenCaseCount(false);
  };
  const handleChangeCaseCount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const caseCount = parseInt(event.target.value);
    setCaseCount(caseCount);

    // Update quantity on change so the price total updates in the dialog in real-time.
    if (hasInvoices) {
      setQuantity(caseCount * caseSize);
    }
  };
  const handleCancelCaseCount = () => {
    setCaseCount(originalCaseCount);
    setOpenCaseCount(false);
  };
  const handleAdjustCaseCount = () => {
    setCaseCount(cases);
    onAdjustItem(index, cases, "cases");
    setOpenCaseCount(false);
  };

  const [pricePerUnit, setPricePerUnit] = useState(0);
  const [displayPricePerUnit, setDisplayPricePerUnit] = useState("");
  useEffect(() => {
    const parsed = parseFloat((originalPricePerUnit / 100).toFixed(2));
    setPricePerUnit(parsed);
    setDisplayPricePerUnit(`${parsed}`);
  }, [originalPricePerUnit]);

  const total = parseFloat((quantity * pricePerUnit || 0).toFixed(2));
  const totalPrice = currencyFormatter.format(total);
  const unitPrice = currencyFormatter.format(pricePerUnit);

  const [openPrice, setOpenPrice] = useState(false);
  const handleOpenPrice = () => setOpenPrice(true);
  const handleClosePrice = () => setOpenPrice(false);
  const handleChangePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const parsed = parseFloat(value);

    setDisplayPricePerUnit(value);

    if (!isNaN(parsed)) {
      setPricePerUnit(parsed);
    }
  };
  const handleCancelPrice = () => {
    const parsed = parseFloat((originalPricePerUnit / 100).toFixed(2));
    setPricePerUnit(parsed);
    setDisplayPricePerUnit(`${parsed}`);
    setOpenPrice(false);
  };
  const handleAdjustPrice = () => {
    onAdjustItem(index, Math.round(pricePerUnit * 100), "pricePerUnit");
    setOpenPrice(false);
  };
  const handleRefund = () => {
    onRefundLineItem(String(total), `Refund: ${productName} ${subName}`);
  };
  const handleRefundByWeight = () => {
    onRefundLineItemByWeight(quantity, unit, String(total), `Refund: ${productName} ${subName}`);
  };

  return (
    <>
      <ListItem alignItems="flex-start" disabled={originalQuantity === 0} disableGutters>
        <ListItemAvatar>
          <Badge color="primary" badgeContent={quantity || 0}>
            <Avatar variant="rounded" src={productImage} />
          </Badge>
        </ListItemAvatar>
        <ListItemText
          className={classes.listItemText}
          disableTypography
          primary={
            <Typography component="div" variant="body1" color="textPrimary">
              <Link component={RouterLink} to={Routes.PRODUCT.replace(":id", productId)}>
                {productName}
              </Link>
              {sku && (
                <Typography component="span" variant="body2">
                  <code className={classes.sku}>{sku}</code>
                </Typography>
              )}
            </Typography>
          }
          secondary={
            <div>
              <Typography component="div" variant="body1" color="textSecondary">
                {subName}
              </Typography>

              <ul className={classes.attributes}>
                <Typography className={classes.attribute} component="li" variant="body2" color="textPrimary">
                  <Box fontWeight={500}>
                    {unitLabel(unit, quantity || 0)} &times; {unitPrice}/{unitLabel(unit)}
                  </Box>
                </Typography>

                <Typography className={classes.attribute} component="li" variant="body2" color="textPrimary">
                  Case size: {unitLabel(unit, caseSize || 0)} &bull; Cases ordered: {cases || 0}
                </Typography>
                <Typography className={classes.attribute} component="li" variant="body2" color="textPrimary">
                  Line item total: {totalPrice}
                </Typography>
              </ul>

              <Typography className={classes.actions} component="div" variant="body2" color="textPrimary">
                <Box mt={2}>
                  {isPaid ? (
                    <ButtonGroup color="secondary" size="small" aria-label="refund actions">
                      <Button onClick={handleRefund}>Refund by price</Button>
                      <Button onClick={handleRefundByWeight}>Refund by weight</Button>
                    </ButtonGroup>
                  ) : (
                    order.isEditable && (
                      <ButtonGroup color="primary" size="small" aria-label="line item actions">
                        {hasInvoices ? (
                          <Button onClick={handleOpenCaseCount}>Adjust case count</Button>
                        ) : (
                          <Button onClick={handleOpenQuantity}>Adjust quantity</Button>
                        )}
                        <Button onClick={handleOpenPrice}>Adjust price</Button>
                        <Button onClick={handleRemoveItem}>Remove item</Button>
                      </ButtonGroup>
                    )
                  )}
                </Box>
              </Typography>
            </div>
          }
        />
      </ListItem>

      <Dialog
        open={openCaseCount}
        onClose={handleCloseCaseCount}
        aria-labelledby={`form-dialog-title-${variantId}`}
        fullScreen={isXS}
      >
        <DialogTitle id={`form-dialog-title-${variantId}`}>Adjust case count</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            <Typography>
              Adjust the case count for&nbsp;
              <strong>
                {productName} - {subName}
              </strong>
              .
            </Typography>
          </DialogContentText>
          <TextField
            InputProps={{
              endAdornment: <InputAdornment position="end">{totalPrice}</InputAdornment>,
              onWheel: OnWheelBlur,
            }}
            fullWidth
            label="Case count"
            onChange={handleChangeCaseCount}
            size="small"
            type="number"
            value={cases}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelCaseCount} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleAdjustCaseCount}
            color="primary"
            variant="contained"
            disabled={Number.isNaN(cases) || cases <= 0}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openQuantity}
        onClose={handleCloseQuantity}
        aria-labelledby={`form-dialog-title-${variantId}`}
        fullScreen={isXS}
      >
        <DialogTitle id={`form-dialog-title-${variantId}`}>Adjust quantity</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            <Typography>
              Adjust the quantity for&nbsp;
              <strong>
                {productName} - {subName}
              </strong>
              .
            </Typography>
          </DialogContentText>
          <TextField
            InputProps={{
              endAdornment: <InputAdornment position="end">{totalPrice}</InputAdornment>,
              onWheel: OnWheelBlur,
            }}
            fullWidth
            label="Quantity"
            onChange={handleChangeQuantity}
            size="small"
            type="number"
            value={displayQuantity}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelQuantity} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleAdjustQuantity}
            color="primary"
            variant="contained"
            disabled={Number.isNaN(quantity) || quantity <= 0}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openPrice}
        onClose={handleClosePrice}
        aria-labelledby={`form-dialog-title-${variantId}`}
        fullScreen={isXS}
      >
        <DialogTitle id={`form-dialog-title-${variantId}`}>Adjust price</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            <Typography>
              Adjust the price for&nbsp;
              <strong>
                {productName} - {subName}
              </strong>
              .
            </Typography>
          </DialogContentText>
          <TextField
            InputProps={{
              endAdornment: <InputAdornment position="end">per {unitLabel(unit)}</InputAdornment>,
              onWheel: OnWheelBlur,
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            inputProps={{
              step: "0.01",
            }}
            fullWidth
            label="Price"
            onChange={handleChangePrice}
            size="small"
            type="number"
            value={displayPricePerUnit}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelPrice} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleAdjustPrice} color="primary" variant="contained">
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LineItem;
